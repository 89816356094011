export const isOnScreen = (top, bottom) => {
  return top >= 0 || bottom >= 0;
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getPostDate = (date) => {
  const d = new Date(date);
  return `${d.getDate()} ${monthNames[d.getMonth()]} ${d.getFullYear()}`;
};

export const getShortPostDate = (date) => {
  const d = new Date(date);
  return `${d.getDate()} ${monthShortNames[d.getMonth()]} ${d.getFullYear()}`;
};

export const getReadingTime = (wordsCount) => {
  const minutes = Math.floor(wordsCount / 120);
  return minutes >= 1 ? `${minutes} minute${minutes === 1 ? '' : 's'} reading` : '';
};

export const getShortPostDateOnBlog = (date) => {
  const d = new Date(date);
  return `on ${monthShortNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
};

export const trimStringToMaximum = (input, maxLength) => {
  if (input.length <= maxLength) {
    return input;
  }

  const trimmedString = input.substr(0, maxLength);
  // check if the last character is part of a word
  if (/\s/.test(input[maxLength - 1])) {
    return trimmedString;
  } else {
    return trimmedString.substr(0, trimmedString.lastIndexOf(' '));
  }
};
