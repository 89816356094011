import React from 'react';

import analyticsIcon from './images/analytics.svg';
import informationArchitectureIcon from './images/inf-arch.svg';
import researchIcon from './images/research.svg';
import modernUIIcon from './images/ui.svg';
import dataVisualizationIcon from './images/visualisation.svg';
import ABTestsIcon from './images/tests.svg';
import UXPrototypingIcon from './images/ux.svg';
import graphicalUIIcon from './images/graphical-ui.svg';

import activeAnalyticsIcon from './images/analytics-active.svg';
import activeInformationArchitecture from './images/inf-arch-active.svg';
import activeResearch from './images/research-active.svg';
import activeModernUI from './images/ui-active.svg';
import activeDataVisualization from './images/visualisation-active.svg';
import activeABTests from './images/tests-active.svg';
import activeUXPrototyping from './images/ux-active.svg';
import activeGraphicalUI from './images/graphical-ui-active.svg';

import * as styles from './revamping-network-app.module.scss';

export const parapgraphsData = {
  sectionTitle: (
    <h2>
      Our approach to revamping your <span className={styles.networkString}>network</span> application
    </h2>
  ),
  menuData: [
    'Analytics',
    'Information Architecture',
    'Research',
    'Modern UI',
    'Data Visualization',
    'A/B Tests',
    'UX Prototyping',
    'Graphical UI',
  ],
  sliderData: [
    {
      id: 'tile-1',
      title: 'Analytics',
      description: 'Analyze business and end-user needs specific for your network application.',
      icon: analyticsIcon,
      activeIcon: activeAnalyticsIcon,
    },
    {
      id: 'tile-2',
      title: 'Information Architecture',
      description: 'Create an information architecture for information-heavy environments.',
      icon: informationArchitectureIcon,
      activeIcon: activeInformationArchitecture,
    },
    {
      id: 'tile-3',
      title: 'Research',
      description: 'Research ideas and provide solution benchmarks.',
      icon: researchIcon,
      activeIcon: activeResearch,
    },
    {
      id: 'tile-4',
      title: 'Modern UI',
      description: <>Redesign your UI according to today&apos;s leading usability standards.</>,
      icon: modernUIIcon,
      activeIcon: activeModernUI,
    },
    {
      id: 'tile-5',
      title: 'Data Visualization',
      description: 'Ensure proper heavy data visualization.',
      icon: dataVisualizationIcon,
      activeIcon: activeDataVisualization,
    },
    {
      id: 'tile-6',
      title: 'A/B Tests',
      description: 'Conduct usability tests on end-users to iron out wrinkles.',
      icon: ABTestsIcon,
      activeIcon: activeABTests,
    },
    {
      id: 'tile-7',
      title: 'UX Prototyping',
      description:
        'Build an interactive clickable UX prototype to demonstrate the product idea and show the navigation flow.',
      icon: UXPrototypingIcon,
      activeIcon: activeUXPrototyping,
    },
    {
      id: 'tile-8',
      title: 'Graphical UI',
      description: 'Build a graphical user interface.',
      icon: graphicalUIIcon,
      activeIcon: activeGraphicalUI,
    },
  ],
};
