import React from 'react';

import cx from 'classnames';

import * as styles from './animated-icon-pulsing-circle.module.scss';

export const AnimatedIconPulsingCircle = ({ children, wrapperClassName, action }) => {
  return (
    <div className={cx(wrapperClassName, styles.wrapper)} onClick={action}>
      <div className={styles.pulsingCircle} />
      {children}
    </div>
  );
};
