import React from 'react';

export const DribbbleIcon = () => {
  return (
    <svg role="img" width="24" height="24" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Dribbble</title>
      <path
        d="M9.53711 19C14.7669 19 19 14.7676 19 9.53711C19 4.32571 14.7846 0 9.53711 0C4.3115 0 0 4.3086 0 9.53711C0 14.7892 4.33092 19 9.53711 19ZM4.65288 16.3362C5.34274 13.7073 7.80022 11.5683 10.9435 10.6211C11.6806 12.7958 12.1356 15.0925 12.2738 17.4256C11.4161 17.7239 10.4953 17.8867 9.53711 17.8867C7.71788 17.8867 6.03143 17.312 4.65288 16.3362ZM13.3589 16.9593C13.193 14.6891 12.7497 12.4744 12.0362 10.3461C13.9734 9.95198 15.9931 10.0262 17.8283 10.5225C17.4968 13.3291 15.7683 15.7136 13.3589 16.9593ZM11.0205 7.7692C12.9093 7.06528 14.9119 5.89793 16.001 4.21104C17.1497 5.62729 17.8506 7.42651 17.8848 9.38693C15.9099 8.89161 13.7441 8.83884 11.6551 9.28416C11.4529 8.75607 11.2485 8.26974 11.0205 7.7692ZM15.2119 3.36405C14.3083 4.9986 12.2844 6.12334 10.5354 6.76202C9.55653 4.8364 8.35903 3.07979 6.95902 1.51728C7.77195 1.25534 8.63822 1.11328 9.53711 1.11328C11.7264 1.11328 13.7212 1.96825 15.2119 3.36405ZM5.8569 1.96085C7.26807 3.48118 8.47688 5.22242 9.45448 7.1134C7.01295 7.81515 4.14958 8.05491 1.3126 7.71368C1.87388 5.18009 3.57917 3.07182 5.8569 1.96085ZM1.14459 8.81493C4.18466 9.18559 7.28648 8.9235 9.94981 8.12753C10.1672 8.59937 10.3711 9.07919 10.5602 9.56639C7.50117 10.4937 4.73014 12.5828 3.7205 15.5712C2.11494 14.05 1.11328 11.9073 1.11328 9.53711C1.11328 9.29387 1.1243 9.05309 1.14459 8.81493Z"
        fill="#6A60A8"
      />
    </svg>
  );
};
