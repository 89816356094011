export const sliderSettings = {
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  className: 'testimonial-slider',
  pauseOnFocus: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
