import React from 'react';

import rightArrow from './right-arrow.svg';
import blogTileBackground from './blog-tile-background.svg';

import { getShortPostDate } from '@src/utils/calculations';

import * as styles from './blog-card.module.scss';
import { Link } from '@commons/link/Link';

export const BlogCard = ({ text, url, date }) => {
  return (
    <div className={styles.mainContainer}>
      <Link href={url} className={styles.cardContainer} id="hidden-arrow-activator">
        <div className={styles.featuredImage}>
          <img loading="lazy" src={blogTileBackground} alt="Codilime blog" />
        </div>
        <div className={styles.dateContainer}>{getShortPostDate(date)}</div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{text}</div>
          <div className={styles.arrowContainer} id="hidden-arrow">
            <img loading="lazy" src={rightArrow} alt="right arrow" width="18px" height="14px" />
          </div>
        </div>
      </Link>
    </div>
  );
};
