// extracted by mini-css-extract-plugin
export var activeLink = "menu-module--active-link--12bea";
export var animatedButtonContainer = "menu-module--animated-button-container--6fb1e";
export var backdrop = "menu-module--backdrop--a6183";
export var link = "menu-module--link--c2c4c";
export var logo = "menu-module--logo--4e7f1";
export var logoImage = "menu-module--logo-image--f9961";
export var logoImageBottom = "menu-module--logo-image-bottom--f7f59";
export var menu = "menu-module--menu--6741f";
export var menuFooter = "menu-module--menu-footer--78aa6";
export var menuWrapper = "menu-module--menu-wrapper--1a323";
export var socialMediaContainer = "menu-module--social-media-container--2f031";