import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { appendTooltip } from '@pages-impl/ux-ui-design/shared/hubspot-form/appendTooltip';
import { LoadingSpinner } from '@commons/loading-spinner/LoadingSpinner';
import { EventTypes, connectFormToGTM, useDetectIntersectionForGTM } from '@src/utils/gtm';
import { styleInputs } from '../helpers/styleInputs';

import { privatePolicyInfo } from '@commons/append-tooltip/payload';

import './hubspot-form.module.scss';
import '@pages-impl/ux-ui-design/shared/hubspot-form/common-forms-style.module.scss';

export const HubspotContactForm = ({ runSetFormSubmitted }) => {
  const policyInfo = privatePolicyInfo.type1;

  const onReady = () => {
    appendTooltip('#ux-ui-design-contact-form', policyInfo);
    styleInputs('ux-ui-design-contact-form');
  };

  const onFormReady = () => {
    connectFormToGTM('#ux-ui-design-contact-form', 'contactFooter');

    const inputElement = document.querySelector(
      '#ux-ui-design-contact-form form .hs-fieldtype-textarea[name="message"]',
    );
    if (inputElement) {
      inputElement.value = '';
      inputElement.dispatchEvent(new Event('input', { bubbles: true }));
    }
  };

  const formRef = useDetectIntersectionForGTM({
    event: EventTypes.FormFooterView,
  });

  return (
    <div className="old-hubspot-form" ref={formRef}>
      <HubspotForm
        region="na1"
        portalId="5206705"
        formId="b52c41bf-ff8a-4e22-9834-3a319c2e1526"
        loading={<LoadingSpinner />}
        onReady={onReady}
        onSubmit={() => undefined}
        onFormSubmitted={() => {
          runSetFormSubmitted(1);
        }}
        onFormReady={onFormReady}
      />
    </div>
  );
};
