import React from 'react';

export const BehanceIcon = () => {
  return (
    <svg role="img" width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Behance</title>
      <path
        d="M16.2676 19.2114V18.1129C16.2676 16.3523 14.845 14.9438 13.0986 14.9438L13.7887 14.4649C14.9155 13.7044 15.5915 12.4227 15.5915 11.0706C15.5915 9.97199 15.1549 8.98603 14.4366 8.2818C13.7323 7.56354 12.7465 7.12695 11.6478 7.12695H2V24.4509H11.0281C13.9154 24.4509 16.2676 22.0987 16.2676 19.2114ZM5.67603 10.0002H10.1408C11.2394 10.0002 12.1268 10.8875 12.1268 11.986C12.1268 13.0847 11.2394 13.972 10.1408 13.972H5.67603V10.0002ZM5.67603 21.6762V16.7044H10.3239C11.7042 16.7044 12.8169 17.817 12.8169 19.1833C12.8169 20.5635 11.7042 21.6762 10.3239 21.6762H5.67603Z"
        fill="#6A60A8"
      />
      <path
        d="M23.6197 11.5078C20.0986 11.5078 17.2395 14.4937 17.2395 18.1838C17.2395 21.874 20.0986 24.874 23.6197 24.874C26.3662 24.874 28.7183 23.043 29.6056 20.4796H26.5211C25.7747 22.3388 23.6197 22.0289 23.6197 22.0289C20.6338 21.8036 20.7465 18.9444 20.7465 18.9444H29.9577C29.9859 18.6909 30 18.4374 30 18.1838C30 14.4937 27.1408 11.5078 23.6197 11.5078ZM21.0141 16.705C21.0141 15.1979 22.2395 13.9726 23.7465 13.9726C25.2676 13.9726 26.493 15.1979 26.493 16.705H21.0141Z"
        fill="#6A60A8"
      />
      <path d="M20.15 8.30078H27.318V9.99878H20.15V8.30078Z" fill="#6A60A8" />
    </svg>
  );
};
