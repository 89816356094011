// extracted by mini-css-extract-plugin
export var activeCardContainer = "slider-card-module--active-card-container--e8f5b";
export var activeCardDescription = "slider-card-module--active-card-description--ca8f5";
export var activeCardTitle = "slider-card-module--active-card-title--d6ab6";
export var cardContainer = "slider-card-module--card-container--f4186";
export var cardDescription = "slider-card-module--card-description--57f68";
export var cardOuterContainer = "slider-card-module--card-outer-container--9e892";
export var cardTitle = "slider-card-module--card-title--7deb5";
export var fistCircle = "slider-card-module--fist-circle--72b70";
export var inactiveCardContainer = "slider-card-module--inactive-card-container--44ec4";
export var inactiveCardDescription = "slider-card-module--inactive-card-description--e75e0";
export var inactiveCardTitle = "slider-card-module--inactive-card-title--2625e";
export var inactiveIcon = "slider-card-module--inactive-icon--1ab81";
export var secondCircle = "slider-card-module--second-circle--13be7";
export var semicircle = "slider-card-module--semicircle--ccdeb";
export var thirdCircle = "slider-card-module--third-circle--b3d5a";