import React from 'react';

import { HubspotContactForm } from '../../HubspotForm';
import * as styles from './complete-form.module.scss';

export const CompleteForm = ({ setFormSide, formTitle }) => {
  return (
    <div className={styles.completeFormContainer}>
      <p className={styles.formTitle}>{formTitle}</p>
      <div id="ux-ui-design-contact-form">
        <HubspotContactForm runSetFormSubmitted={setFormSide} />
      </div>
    </div>
  );
};
