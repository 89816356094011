import React from 'react';
import cx from 'classnames';

import correctIcon from '@pages-impl/ux-ui-design/process-pillars/images/correctIcon.svg';

import * as styles from './control-panel.module.scss';

export const ControlPanel = ({ pillars, activePillars = 'Strategy', setActivePillars }) => {
  let activePillarsDescription = pillars[0].description;

  return (
    <div className={styles.pillarsDescription}>
      <div className={styles.pillarsMenu}>
        {pillars.map((pillar, index) => {
          const pillarsName = pillar.name;
          if (pillarsName === activePillars) {
            activePillarsDescription = pillars[index].description;
            return (
              <div className={cx(styles.iconContainerInMenu, styles.activeIconInMenu)} key={pillarsName}>
                <img loading="lazy" src={pillar.icon} className={styles.imgOnMenu} alt="pilarIcon" />
                <div className={styles.pillarName}>{pillarsName}</div>
              </div>
            );
          } else {
            return (
              <div
                key={'processPillarsContainer' + index}
                className="processPillarsContainerToClick"
                onClick={(e) =>
                  setActivePillars(e.target.closest('.processPillarsContainerToClick').lastChild.lastChild.innerHTML)}
              >
                <div className={styles.iconContainerInMenu} key={pillar.name}>
                  <img loading="lazy" src={pillar.icon} className={styles.imgOnMenu} alt="pilarIcon" />
                  <div className={styles.pillarName}>{pillar.name}</div>
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className={styles.pillarFeatures}>
        {activePillarsDescription.map((activePillarPoint, index) => (
          <div key={`point${index}`} className={styles.pillarPoint}>
            <img loading="lazy" src={correctIcon} className={styles.correctIcon} alt="correctIcon" />
            <p>{activePillarPoint}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
