import React from 'react';

import { AnimatedParagraphTitle } from '@commons/animated-paragraph-title/AnimatedParagraphTitle';

import closeIcon from '@pages-impl/ux-ui-design/shared/images/cross.svg';
import * as styles from './form-border.module.scss';

export const FormBorder = (props) => {
  return (
    <div className={styles.formContainer}>
      <div className={styles.firstRow}>
        <AnimatedParagraphTitle
          title={props.secondHeading}
          paragraphExist
          paragraph={props.firstHeading}
          classNames={{ titleCustomClass: styles.titleCustomClass }}
        />
      </div>
      <div className={styles.secondRow}>{props.children}</div>
      <div className={styles.crossIcon} onClick={() => props.setFormSide(0)}>
        <img loading="lazy" src={closeIcon} alt="Close icon" />
      </div>
    </div>
  );
};
