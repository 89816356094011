import React, { useState, useEffect } from 'react';

import { MenuToggler } from './menu-toggler/MenuToggler';

import { Menu } from './menu/Menu';

import { linksData } from './payload';

import { getAnchor, createObserver } from './helpers';

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentAnchor, setCurrentAnchor] = useState('');

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(
    () =>
      createObserver(
        linksData.map((link) => link.url),
        setCurrentAnchor,
      ),
    [],
  );
  useEffect(() => setCurrentAnchor(getAnchor(linksData)), []);

  return (
    <>
      <MenuToggler action={toggleOpen} isOpen={isOpen} />
      {isOpen && <Menu currentAnchor={currentAnchor} toggleOpen={toggleOpen} />}
    </>
  );
};
