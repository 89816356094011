import React from 'react';

import { ControlPanel } from './control-panel/ControlPanel';
import { ImageWithScreens } from './image-with-screens/ImageWithScreens';

import { caseStudiesData } from './payload';

import * as styles from './case-studies.module.scss';

export const CaseStudies = () => {
  const { sectionTitle, paragraph, caseStudies } = caseStudiesData;

  return (
    <section className={styles.container} id="case-studies">
      <div className={styles.header}>
        <div className={styles.mainTitle}>{sectionTitle}</div>
        <div className={styles.headerDescription}>{paragraph}</div>
      </div>
      <div className={styles.controlPanel}>
        <ControlPanel caseStudies={caseStudies} />
        <ImageWithScreens />
      </div>
    </section>
  );
};
