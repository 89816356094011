import React from 'react';

import arrowIcon from '../images/upwards-arrow_black.svg';

import './animated-button-styles.scss';
import { Link } from '@commons/link/Link';

export const AnimatedButton = ({ text, iconSrc = arrowIcon, custonAnimatedButtonStyles = '', url, onClick }) => {
  return (
    <div className="animated-button-container">
      <Link href={url}>
        <button className={custonAnimatedButtonStyles} onClick={onClick}>
          <span>{text}</span>
          <svg viewBox="0 0 350 100"></svg>
          <img loading="lazy" src={iconSrc} alt="icon download"></img>
        </button>
      </Link>
    </div>
  );
};
