import React, { useState, useRef } from 'react';

import { AnimatedParagraphTitle } from '@pages-impl/ux-ui-design/shared';
import { VerticalSlider } from './vertical-slider/VerticalSlider';
import { MobileSlider } from './mobile-slider/MobileSlider';

import { parapgraphsData } from './payload';

import * as styles from './revamping-network-app.module.scss';

export const RevampingNetworkApp = () => {
  const { sectionTitle, menuData, sliderData } = parapgraphsData;
  const [activeCard, setActiveCard] = useState('Analytics');
  const sliderdRef = useRef(null);

  const changeSlide = (event) => {
    const titleName = event.target.id;
    setActiveCard(titleName);
    const sliderIndex = menuData.indexOf(titleName);
    sliderdRef.current.slickGoTo(sliderIndex - 1, false);
  };

  return (
    <section className={styles.container} id="design-services">
      <div className={styles.firstColumn}>
        <AnimatedParagraphTitle title={sectionTitle} classNames={{ titleCustomClass: styles.titleCustomClass }} />
        <div className={styles.sideMenu}>
          {menuData.map((singleName) => {
            return (
              <p
                className={`${activeCard === singleName ? styles.activeSingleName : styles.singleName}`}
                key={singleName}
                id={singleName}
                onClick={(event) => changeSlide(event)}
              >
                {singleName}
              </p>
            );
          })}
        </div>
      </div>
      <VerticalSlider
        sliderData={sliderData}
        activeCard={activeCard}
        setActiveCard={setActiveCard}
        forwardedRef={sliderdRef}
      />
      <div className={styles.sliderOnMobile}>
        <MobileSlider sliderData={sliderData} />
      </div>
    </section>
  );
};
