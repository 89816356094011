import { paths } from '@src/constants/pathsEnum';

export const socialMediaChannels = [
  { url: 'https://www.linkedin.com/company/codilime', alt: 'LinkedIn' },
  { url: 'https://www.facebook.com/codilime/', alt: 'Facebook' },
  { url: 'https://twitter.com/codilime', alt: 'Twitter' },
  { url: 'https://www.instagram.com/codilime/', alt: 'Instagram' },
  { url: 'https://dribbble.com/CodiLime', alt: 'Dribbble' },
  { url: 'https://www.behance.net/codilime', alt: 'Behance' },
];

export const formalLinks = [
  {
    name: 'Privacy policy',
    link: paths.PRIVACY_POLICY,
  },
  {
    name: 'Terms of service',
    link: paths.TERMS_OF_SERVICE,
  },
  {
    name: 'Information security policy',
    link: paths.INFORMATION_SECURITY_POLICY,
  },
];
