import React from 'react';

import { Link } from '@commons/link/Link';

import { SocialMediaLink } from '@pages-impl/ux-ui-design/shared';

import { paths } from '@src/constants/pathsEnum';
import { socialMediaChannels, formalLinks } from './payload';

import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './footer.module.scss';

export const Footer = () => {
  return (
    <footer className={styles.container}>
      <div className={styles.footerItem}>
        <Link href={paths.HOME}>
          <StaticImage src="../shared/images/codilime-logo.svg" alt="Codilime logo" placeholder="none" />
        </Link>
      </div>
      <div className={styles.footerItem}>
        {socialMediaChannels.map((channel, index) => (
          <SocialMediaLink key={index} url={channel.url} alt={channel.alt} />
        ))}
      </div>
      <div className={styles.footerItem}>{`© CodiLime ${new Date().getFullYear()} - All rights reserved.`}</div>
      <div className={styles.footerItem}>
        <div className={styles.desktopLinkContainer}>
          <Link href={paths.PRIVACY_POLICY}>Privacy policy</Link> &nbsp;&nbsp;/&nbsp;&nbsp;
          <Link href={paths.TERMS_OF_SERVICE}>Terms of service</Link> &nbsp;&nbsp;/&nbsp;&nbsp;
          <Link href={paths.INFORMATION_SECURITY_POLICY}>Information securtity policy</Link>
        </div>
        <div className={styles.mobileLinkContainer}>
          {formalLinks.map((element, index) => (
            <Link href={element.link} key={index}>
              {element.name}
            </Link>
          ))}
        </div>
      </div>
    </footer>
  );
};
