import React from 'react';
import Slider from 'react-slick';
import { SliderCard } from './slider-card/SliderCard';

import { sliderSettings } from './sliderSettings';
import * as styles from './vertical-slider.module.scss';

export const VerticalSlider = ({ sliderData, activeCard, setActiveCard, forwardedRef }) => {
  const changeActiveCard = () => {
    setActiveCard(
      document.getElementById('design-services').querySelector('.slick-current').nextSibling.querySelector('p')
        .textContent,
    );
  };

  return (
    <div className={styles.secondColumn}>
      <Slider {...sliderSettings} afterChange={changeActiveCard} ref={forwardedRef}>
        {sliderData.map((sliderCardData, index) => (
          <SliderCard key={index} cardData={sliderCardData} activeCard={activeCard} />
        ))}
      </Slider>
    </div>
  );
};
