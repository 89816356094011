// extracted by mini-css-extract-plugin
export var activeSingleName = "revamping-network-app-module--active-single-name--ff2fd";
export var container = "revamping-network-app-module--container--5339a";
export var firstColumn = "revamping-network-app-module--first-column--fea6a";
export var leftToRight = "revamping-network-app-module--left-to-right--aa82a";
export var networkString = "revamping-network-app-module--network-string--e7e11";
export var sideMenu = "revamping-network-app-module--side-menu--24e4d";
export var singleName = "revamping-network-app-module--single-name--5576b";
export var sliderOnDesktop = "revamping-network-app-module--slider-on-desktop--9e413";
export var sliderOnMobile = "revamping-network-app-module--slider-on-mobile--0d887";
export var titleCustomClass = "revamping-network-app-module--title-custom-class--c8bd9";