import React from 'react';

import { ControlPanelOnDesktop } from './control-panel-on-desktop/ControlPanelOnDesktop';
import { ControlPanelOnMobile } from './control-panel-on-mobile/ControlPanelOnMobile';

import { pillarsData } from './payload';

import * as styles from './process-pillars.module.scss';

export const ProcessPillars = () => {
  const { sectionTitle, paragraph, pillars } = pillarsData;

  return (
    <section className={styles.container} id="our-process">
      <div className={styles.header}>
        <div className={styles.mainTitle}>{sectionTitle}</div>
        <div className={styles.headerDescription}>{paragraph}</div>
      </div>
      <div className={styles.panelWithPillarsOnDesktop}>
        <ControlPanelOnDesktop pillars={pillars} />
      </div>
      <div className={styles.panelWithPillarsOnMobile} id="pillarsMenuOnMobile">
        <ControlPanelOnMobile pillars={pillars} />
      </div>
    </section>
  );
};
