export const sliderSettings = {
  arrows: false,
  className: 'process-pillars-mobile-slider',
  pauseOnFocus: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  variableWidth: true,
};
