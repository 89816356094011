import React from 'react';

export const LinkdinIcon = () => {
  return (
    <svg role="img" width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Linkdin</title>
      <path
        d="M19.6 19.5997V12.5677C19.6 9.11168 18.856 6.47168 14.824 6.47168C12.88 6.47168 11.584 7.52768 11.056 8.53568H11.008V6.78368H7.19202V19.5997H11.176V13.2397C11.176 11.5597 11.488 9.95168 13.552 9.95168C15.592 9.95168 15.616 11.8477 15.616 13.3357V19.5757H19.6V19.5997Z"
        fill="#6A60A8"
      />
      <path d="M0.712036 6.78369H4.69604V19.5997H0.712036V6.78369Z" fill="#6A60A8" />
      <path
        d="M2.70402 0.399902C1.43202 0.399902 0.400024 1.4319 0.400024 2.7039C0.400024 3.9759 1.43202 5.0319 2.70402 5.0319C3.97602 5.0319 5.00802 3.9759 5.00802 2.7039C5.00802 1.4319 3.97602 0.399902 2.70402 0.399902Z"
        fill="#6A60A8"
      />
    </svg>
  );
};
