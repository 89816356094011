export const getAnchor = (links) => {
  const currentHash = document.location.hash;
  const knownLinks = links.map((link) => link.url);
  if (knownLinks.includes(currentHash.slice(0, -1))) {
    return currentHash.slice(0, -1);
  } else if (knownLinks.includes(currentHash)) {
    return currentHash;
  } else if (currentHash === '#say-hello') {
    return currentHash;
  } else return links[0].url;
};

export const getVertical = (anchor, links) => {
  if (anchor === '#say-hello') {
    return `188px`;
  }
  const foundIndex = links.findIndex((link) => link.url === anchor);
  return `${-12 + foundIndex * 40}px`;
};

export const createObserver = (ids, callback) => {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  };
  const observerCallback = (observed) => {
    const intersectintElement = observed.find((el) => el.isIntersecting);
    const currentAnchorLocation = intersectintElement && intersectintElement.target.id;
    if (currentAnchorLocation) {
      callback(`#${currentAnchorLocation}`);
    }
  };
  const observer = new IntersectionObserver(observerCallback, options);
  ids.forEach((id) => {
    const element = document.getElementById(id.slice(1));
    element && observer.observe(element);
  });
};
