import React from 'react';

import { Link } from '@commons/link/Link';

import { StaticImage } from 'gatsby-plugin-image';

import { AnimatedIconPulsingCircle } from '@pages-impl/ux-ui-design/shared';

import * as styles from './header.module.scss';

const headerHeadline = (
  <>
    UX/UI Design for complex <span className={styles.networkString}>network</span> applications
  </>
);

const headerParagraph =
  'Design an engaging user experience that solves real business problems. Improve your digital product with a user interface that strikes a balance between efficiency and productivity. Capitalize on CodiLime’s 11 years of experience in designing complex network systems.';

export const Header = () => {
  return (
    <header className={styles.container} id="network-apps-ux">
      <div className={styles.runnersContainer}>
        <div className={styles.mobileVerticalRunner}></div>
        <div className={styles.verticalRunner}></div>
        <div className={styles.verticalRunner}></div>
        <div className={styles.verticalRunner}></div>
        <div className={styles.verticalRunner}></div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.laptopImageContainer}>
          <StaticImage
            src="./images/ux-ui-design-for-applications.webp"
            alt="UX UI design for applications"
            placeholder="none"
          />
        </div>
        <div className={styles.paragraphContainer}>
          <h1 className={styles.headerTitle}>{headerHeadline}</h1>
          <span className={styles.paragraphText}>{headerParagraph}</span>
        </div>
      </div>
      <Link href="#ux-matter">
        <AnimatedIconPulsingCircle wrapperClassName={styles.scrollDownIcon}>
          <StaticImage src="./images/mouse.svg" alt="mouse icon" placeholder="none" />
        </AnimatedIconPulsingCircle>
      </Link>
    </header>
  );
};
