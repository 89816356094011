import React from 'react';
import { AnimatedButton } from '@pages-impl/ux-ui-design/shared/animated-button/AnimatedButton';
import { FormBorder } from '../form-border/FormBorder';

import checkIcon from '@pages-impl/ux-ui-design/shared/images/check.svg';

import * as styles from './accept-form.module.scss';

export const AcceptForm = (props) => {
  const { firstHeading, secondHeading, thankYouTitle, thankYouNote, thankYouButton } = props.data;
  return (
    <FormBorder firstHeading={firstHeading} secondHeading={secondHeading} setFormSide={props.setFormSide}>
      <h1 className={styles.acceptTitle}>{thankYouTitle}</h1>
      <p className={styles.acceptDescription}>{thankYouNote}</p>
      <div className={styles.containerWithButton} id="ux-ui-design-banner-accept-btn">
        <AnimatedButton
          text={thankYouButton}
          iconSrc={checkIcon}
          custonAnimatedButtonStyles={styles.animatedButtonContainer}
          onClick={() => props.setFormSide(0)}
        />
      </div>
    </FormBorder>
  );
};
