import React from 'react';

import Slider from 'react-slick';

import { AnimatedParagraphTitle } from '@commons/animated-paragraph-title/AnimatedParagraphTitle';
import { TestimonialCard } from '@commons/testimonial-card/TestimonialCard';
import { useConnectSliderArrrowsToGTM } from '@src/utils/gtm';
import { testimonials } from './payload';

import { sliderSettings } from './sliderSettings';

import * as styles from './testimonials.module.scss';

const testimonialsTitle = <h2>What do our partners say about us?</h2>;

export const Testimonials = () => {
  useConnectSliderArrrowsToGTM('our-partners-about-us');
  return (
    <section className={styles.container} id="our-partners-about-us">
      <div className={styles.backgroundShapeQuoteContainer}></div>
      <div className={styles.sectionTitleContainer}>
        <AnimatedParagraphTitle title={testimonialsTitle} titleCustomClass={styles.customAnimatedParagraph} />
      </div>
      <div className={styles.testimonialSliderContainer}>
        <div className={styles.sliderStyles}>
          <Slider {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <TestimonialCard text={testimonial.text} person={testimonial.person} key={index} />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};
