import React from 'react';

import { AnimatedButton } from '@pages-impl/ux-ui-design/shared/animated-button/AnimatedButton';

import downloadImage from '../images/new-checklist-image.svg';
import cloudDownload from '../images/cloud-download.svg';

import * as styles from './download-checklist.module.scss';

export const DownloadChecklist = ({ data, setFormSide }) => {
  const { firstHeading, secondHeading, buttonTitle } = data;
  return (
    <div className={styles.outerFormContainer} onClick={() => setFormSide(1)}>
      <div className={styles.innerFormContainer}>
        <div className={styles.firstRow}>
          <div className={styles.descriptionContainer}>
            <h3 className={styles.firstHeading}>{firstHeading}</h3>
            <h3 className={styles.secondHeading}>{secondHeading}</h3>
          </div>
          <div className={styles.containerForImage}>
            <img loading="lazy" src={downloadImage} alt="Checklist" />
          </div>
        </div>
        <div className={styles.secondRow} id="ux-ui-design-banner-download-checklist-btn">
          <AnimatedButton
            text={buttonTitle}
            iconSrc={cloudDownload}
            custonAnimatedButtonStyles={styles.animatedButtonContainer}
          />
        </div>
      </div>
    </div>
  );
};
