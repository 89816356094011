export const socialMediaData = [
  { url: 'https://www.linkedin.com/company/codilime', alt: 'LinkedIn' },
  { url: 'https://www.facebook.com/codilime/', alt: 'Facebook' },
  { url: 'https://www.twitter.com/codilime', alt: 'Twitter' },
  { url: 'https://www.instagram.com/codilime', alt: 'Instagram' },
  { url: 'https://www.dribbble.com/CodiLime', alt: 'Dribbble' },
  { url: 'https://www.behance.net/codilime', alt: 'Behance' },
];

export const linksData = [
  { url: '#network-apps-ux', text: 'UX for network apps' },
  { url: '#our-expertise', text: 'Our expertise' },
  { url: '#our-process', text: 'The Process' },
  { url: '#design-services', text: 'How we can help you' },
  { url: '#case-studies', text: 'Case studies' },
  { url: '#blog', text: 'Our blog' },
];
