import React from 'react';

import { Icon } from './icons/Icon';

import { Link } from '@commons/link/Link';

export const SocialMediaLink = ({ url, alt }) => {
  return (
    <Link href={url}>
      <Icon text={alt} />
    </Link>
  );
};
