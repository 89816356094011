import React from 'react';

import * as styles from './why-does-ux-matter.module.scss';

export const parapgraphsData = {
  sectionTitle: (
    <h2>
      Why does UX design for <span className={styles.networkString}>network</span> apps matter?
    </h2>
  ),
  paragraphOne: (
    <p className={styles.paragraph}>
      Well-crafted UX <strong>helps avoid errors - and cut engineering time by up to 50%.</strong> The benefits are even
      more impressive for network applications, which, due to the amount of information to be processed and visualized,
      are far more complex than standard apps.
    </p>
  ),
  paragraphTwo: (
    <p className={styles.paragraph}>
      With <strong>network apps,</strong> UX designers can draw <strong>5,000 or more screens</strong> (a standard app
      requires about 100 screens). Such complexity requires UX designers to possess considerable{' '}
      <strong>domain knowledge</strong> to meet the project&apos;s business and functional requirements. And this
      knowledge is hard to obtain from the market.
    </p>
  ),
};
