import React, { useState, useEffect, useLayoutEffect } from 'react';

import { CustomSlider } from '@commons/custom-slider/CustomSlider';

import correctIcon from '@pages-impl/ux-ui-design/process-pillars/images/correctIcon.svg';

import * as styles from './description-slider.module.scss';

export const DescriptionSlider = ({ pillars, sliderRef, setActivePillar }) => {
  function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useWindowSize();
  useEffect(() => {
    changeSlideHeight();
  });

  const findSlideHight = (currentwindowWidth, activeSlideIndex) => {
    const windowWidth = [200, 250, 300, 350, 400, 432, 485, 510, 520, 550, 560, 579, 600, 696, 760];
    const slidesHeigh = {
      0: [1068, 748, 620, 595, 500, 440, 408, 408, 408, 408, 408, 400, 344, 344, 344],
      1: [2840, 1836, 1280, 1060, 910, 855, 750, 664, 664, 664, 664, 640, 640, 568, 504],
      2: [1420, 972, 785, 650, 560, 510, 472, 472, 455, 445, 420, 408, 408, 400, 376],
      3: [720, 550, 415, 336, 320, 250, 240, 240, 240, 240, 240, 208, 208, 208, 176],
      4: [990, 648, 520, 435, 400, 370, 328, 296, 296, 296, 296, 296, 296, 232, 232],
    };

    for (let i = 0; i < windowWidth.length; i++) {
      if (currentwindowWidth <= windowWidth[i]) {
        let iA = i - 1;
        let iB = i;
        if (i === -1) {
          iA = i;
          iB = i + 1;
        }
        const yA = slidesHeigh[activeSlideIndex][iA];
        const yB = slidesHeigh[activeSlideIndex][iB];
        if (yA === yB) {
          return yA;
        } else {
          return ((yA - yB) * (currentwindowWidth - windowWidth[iA])) / (windowWidth[iA] - windowWidth[iB]) + yA;
        }
      }
    }
    return slidesHeigh[activeSlideIndex][-1];
  };

  const changeSlideHeight = () => {
    const activeSlideIndex = document
      .querySelector('#process-pillars-description-mobile-slider .slick-current')
      .getAttribute('data-index');
    const currentwindowWidth = window.screen.width;
    const index = findSlideHight(currentwindowWidth, activeSlideIndex);
    document.querySelector('#process-pillars-description-mobile-slider .slick-track').style.height = index + 'px';
    return activeSlideIndex;
  };
  const changeActivePillarUseDescription = () => {
    const activeSlideIndex = changeSlideHeight();
    setActivePillar(activeSlideIndex);
  };

  return (
    <div className={styles.sliderWithDescription} id="process-pillars-description-mobile-slider">
      <CustomSlider
        settings={{ autoplay: false, dots: false }}
        sliderRef={sliderRef}
        handleAfterChange={changeActivePillarUseDescription}
      >
        {pillars.map((pillar, index) => (
          <div className={styles.pillarFeatures} key={index}>
            {pillar.description.map((pillarPoint, index) => (
              <div key={`description${index}`} className={styles.pillarPoint}>
                <img loading="lazy" src={correctIcon} alt="correct icon" className={styles.correctIcon} />
                <p>{pillarPoint}</p>
              </div>
            ))}
          </div>
        ))}
      </CustomSlider>
    </div>
  );
};
