import React from 'react';

import { AnimatedIconWithCircles } from '@pages-impl/ux-ui-design/shared';

import * as styles from './expertise-card.module.scss';

export const ExpertiseCard = ({ text, iconSrc, rotationMultiplier }) => {
  return (
    <div className={styles.cardContainer}>
      <AnimatedIconWithCircles iconSrc={iconSrc} rotationMultiplier={rotationMultiplier} />
      {text}
    </div>
  );
};
