import React from 'react';

export const FacebookIcon = () => {
  return (
    <svg role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Facebook</title>
      <path
        d="M9.69604 13.2478C9.62404 13.2478 8.04004 13.2478 7.32004 13.2478C6.93604 13.2478 6.81604 13.1038 6.81604 12.7438C6.81604 11.7838 6.81604 10.7998 6.81604 9.83983C6.81604 9.45583 6.96004 9.33583 7.32004 9.33583H9.69604C9.69604 9.26383 9.69604 7.87183 9.69604 7.22383C9.69604 6.26383 9.86404 5.35183 10.344 4.51183C10.848 3.64783 11.568 3.07183 12.48 2.73583C13.08 2.51983 13.68 2.42383 14.328 2.42383H16.68C17.016 2.42383 17.16 2.56783 17.16 2.90383V5.63983C17.16 5.97583 17.016 6.11983 16.68 6.11983C16.032 6.11983 15.384 6.11983 14.736 6.14383C14.088 6.14383 13.752 6.45583 13.752 7.12783C13.728 7.84783 13.752 8.54383 13.752 9.28783H16.536C16.92 9.28783 17.064 9.43183 17.064 9.81583V12.7198C17.064 13.1038 16.944 13.2238 16.536 13.2238C15.672 13.2238 13.824 13.2238 13.752 13.2238V21.0478C13.752 21.4558 13.632 21.5998 13.2 21.5998C12.192 21.5998 11.208 21.5998 10.2 21.5998C9.84004 21.5998 9.69604 21.4558 9.69604 21.0958C9.69604 18.5758 9.69604 13.3198 9.69604 13.2478Z"
        fill="#6A60A8"
      />
    </svg>
  );
};
