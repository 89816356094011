export const styleInputs = (id) => {
  const form = document.getElementById(id);
  const inputs = form?.querySelectorAll('input');
  inputs?.forEach((input) => {
    const handleChange = () => {
      const currentValue = input.value;
      const isEmpty = currentValue === '' || (input.type === 'tel' && currentValue === '+48 ');
      const label = input.closest('.hs-form-field').firstChild;
      const setVisible = () => {
        label.style.cssText = 'display: block;';
        input.style.cssText = 'top: 10px;';
      };
      const setHidden = () => {
        [input, label].forEach((element) => element.removeAttribute('style'));
      };
      isEmpty ? setHidden() : setVisible();
    };
    input.type !== 'submit' && input.addEventListener('change', handleChange);
  });
};
