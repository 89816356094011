export const privatePolicyInfo = {
  type1:
    'CodiLime is committed to processing your personal information for communication purposes. From time to time, we would like to contact you about our services, as well as other content that may be of interest to you.  By clicking ‘Submit’, you consent to allow CodiLime to store and process your personal data.  Your consent to receive marketing communication, including our newsletter, is non-obligatory. ',
  type2:
    'CodiLime is committed to processing your personal information for communication purposes and in order to send you our newsletter. From time to time, we would like to contact you about our services, as well as other content that may be of interest to you. By clicking ‘Submit’, you consent to allow CodiLime to store and process your personal data.',
  type3:
    'CodiLime is committed to processing your personal information for communication purposes and in order to send you the content you requested and our newsletter. From time to time, we would like to contact you about our services, as well as other content that may be of interest to you. By clicking ‘Submit’, you consent to allow CodiLime to store and process your personal data.',
  type4:
    'CodiLime is committed to processing your personal information for communication purposes and in order to send you our newsletter. From time to time, we would like to contact you about our services, as well as other content that may be of interest to you. By clicking ‘Sign up’, you consent to allow CodiLime to store and process your personal data.',
};
