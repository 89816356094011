import React from 'react';

import ellipseIcon from '../images/ellipse.svg';

import './form-border.scss';
import * as styles from './form-border.module.scss';

export const FormBorder = ({ firstHeading, secondHeading }) => {
  const numberOfEllipses = 4;

  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.descriptionInnerContainer}>
        <h3 className={styles.firstHeading}>{firstHeading}</h3>
        <p className={styles.secondHeading}>{secondHeading}</p>
      </div>
      {[...Array(numberOfEllipses)].map((el, index) => (
        <img
          key={`ellipse${index}`}
          className={`ellipse${index}-ux-ui-design-form`}
          src={ellipseIcon}
          alt="ellipse"
        >
        </img>
      ))}
    </div>
  );
};
