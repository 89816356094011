export const sliderSettings = {
  arrows: false,
  className: 'blog-post-slider',
  dots: true,
  infinite: false,
  initialSlide: 4,
  pauseOnFocus: true,
  rtl: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        infinite: true,
        slidesToShow: 1,
        variableWidth: true,
      },
    },
  ],
};
