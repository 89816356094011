import React from 'react';

import cx from 'classnames';

import * as styles from './animated-icon.module.scss';

export const AnimatedIconWithCircles = ({ iconSrc, rotationMultiplier, iconsCustomClass = '' }) => {
  return (
    <div className={styles.iconOuterWrapper}>
      <div className={cx(iconsCustomClass, styles.iconInnerWrapper)}>
        <img loading="lazy" src={iconSrc} alt="expertise icon" className={styles.iconStyles} />
        <div className={styles.setOfEllipses} style={{ animationDuration: `${rotationMultiplier * 8}s` }}></div>
      </div>
    </div>
  );
};
