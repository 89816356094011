import React from 'react';

import { AnimatedButton, AnimatedParagraphTitle, ExpertiseCard } from '@pages-impl/ux-ui-design/shared';

import { ourExpertiseData } from './payload';
import { paths } from '@src/constants/pathsEnum';

import arrowIcon from '../shared/images/upwards-arrow_black.svg';

import * as styles from './our-expertise-in-design.module.scss';

export const OurExpertiseInDesigning = () => {
  const { sectionTitle, ourCompetenciesColumnOne, ourCompetenciesColumnTwo, description, callToAction } =
    ourExpertiseData;

  return (
    <section className={styles.container} id="our-expertise">
      <div className={styles.column}>
        {ourCompetenciesColumnOne.map((el, index) => (
          <ExpertiseCard iconSrc={el.iconSrc} text={el.text} key={index} rotationMultiplier={index + 4} />
        ))}
      </div>
      <div className={styles.column}>
        {ourCompetenciesColumnTwo.map((el, index) => (
          <ExpertiseCard iconSrc={el.iconSrc} text={el.text} key={index} rotationMultiplier={index + 6} />
        ))}
        <div className={styles.ctaMobileContainer}>
          <div className={styles.mobileParagraph}>{callToAction}</div>
          <AnimatedButton
            text="Discover our Product Design services"
            iconSrc={arrowIcon}
            url={paths.SERVICES.PRODUCT_DESIGN}
          />
        </div>
      </div>
      <div className={styles.paragraphContainer}>
        <AnimatedParagraphTitle noBorder title={sectionTitle} />
        <div className={styles.paragraph}>{description}</div>
        <div className={styles.ctaContainer}>
          <div className={styles.paragraph}>{callToAction}</div>
          <AnimatedButton
            text="Discover our Product Design services"
            iconSrc={arrowIcon}
            url={paths.SERVICES.PRODUCT_DESIGN}
            custonAnimatedButtonStyles={styles.animatedButtonContainer}
          />
        </div>
      </div>
    </section>
  );
};
