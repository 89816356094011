import React from 'react';

import { AnimatedIconWithCircles } from '@pages-impl/ux-ui-design/shared';

import * as styles from './slider-card.module.scss';

export const SliderCard = ({ cardData }) => {
  const { title, description, activeIcon } = cardData;
  return (
    <div className={styles.cardOuterContainer}>
      <div className={styles.cardContainer}>
        <AnimatedIconWithCircles iconSrc={activeIcon} rotationMultiplier={4} />
        <p className={styles.cardTitle}>{title}</p>
        <p className={styles.cardDescription}>{description}</p>
      </div>
    </div>
  );
};
