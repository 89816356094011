import React from 'react';

import { FormBorder } from '../form-border/FormBorder';
import { HubspotFormChecklist } from './contact-hubspot-form/ContactHubspotForm';

import * as styles from './complete-form.module.scss';

export const CompleteForm = (props) => {
  const { firstHeading, secondHeading, formTitle } = props.data;
  return (
    <FormBorder firstHeading={firstHeading} secondHeading={secondHeading} setFormSide={props.setFormSide}>
      <p className={styles.formTitle}>{formTitle}</p>
      <div id="ux-ui-design-form-with-checklist">
        <HubspotFormChecklist runSetFormSubmitted={props.setFormSide} />
      </div>
    </FormBorder>
  );
};
