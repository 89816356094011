import React from 'react';

import cx from 'classnames';

import * as styles from './numbered-paragraph.module.scss';

export const NumberedParagraph = ({ number, text, customStyles = '' }) => {
  return (
    <div className={cx(styles.horizontalParagraphLayout, customStyles)}>
      <span className={styles.numberStyles}>{number}</span>
      {text}
    </div>
  );
};
