import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import cx from 'classnames';

import { AnimatedIconPulsingCircle } from '@pages-impl/ux-ui-design/shared';

import * as styles from './menu-toggler.module.scss';

export const MenuToggler = ({ isOpen, action }) => (
  <>
    <AnimatedIconPulsingCircle wrapperClassName={styles.toggler} action={action}>
      <StaticImage
        src="../images/close.svg"
        alt="close menu"
        className={isOpen ? cx(styles.togglerIcon, styles.shown) : cx(styles.togglerIcon, styles.hidden)}
        placeholder="none"
      />
      <StaticImage
        src="../images/menu-trigger.svg"
        alt="open menu"
        className={isOpen ? cx(styles.togglerIcon, styles.hidden) : cx(styles.togglerIcon, styles.shown)}
        placeholder="none"
      />
    </AnimatedIconPulsingCircle>
    <div className={styles.togglerMobile} onClick={action}>
      <StaticImage
        src="../images/close-mobile.svg"
        alt="close menu"
        className={isOpen ? cx(styles.togglerIcon, styles.shown) : cx(styles.togglerIcon, styles.hidden)}
        placeholder="none"
      />
      <StaticImage
        src="../images/menu-trigger-mobile.svg"
        alt="open menu"
        className={isOpen ? cx(styles.togglerIcon, styles.hidden) : cx(styles.togglerIcon, styles.shown)}
        placeholder="none"
      />
    </div>
  </>
);
