import React from 'react';

import strategyInMenu from './images/strategy-in-menu.svg';
import researchInMenu from './images/research-in-menu.svg';
import designInMenu from './images/design-in-menu.svg';
import optimizationInMenu from './images/optimization-in-menu.svg';
import testingInMenu from './images/testing-in-menu.svg';
import strategyOnLemon from './images/strategy-on-lemon.svg';
import researchOnLemon from './images/research-on-lemon.svg';
import designOnLemon from './images/design-on-lemon.svg';
import optimizationOnLemon from './images/optimization-on-lemon.svg';
import testingOnLemon from './images/testing-on-lemon.svg';
import strategyOnLemonActive from './images/strategy-on-lemon-active.svg';
import researchOnLemonActive from './images/research-on-lemon-active.svg';
import designOnLemonActive from './images/design-on-lemon-active.svg';
import optimizationOnLemonActive from './images/optimization-on-lemon-active.svg';
import testingOnLemonActive from './images/testing-on-lemon-active.svg';

export const pillarsData = {
  sectionTitle: 'The five pillars of our process',
  paragraph:
    "Based on our experience in designing complex network systems and our expertise in the network industry, we help you meet your project's business and functional requirements. Our process is based on five pillars:",
  pillars: [
    {
      name: 'Strategy',
      styleName: 'strategy-icon',
      icon: strategyInMenu,
      iconOnLemon: strategyOnLemon,
      activeIconOnLemon: strategyOnLemonActive,
      description: [
        <>
          <strong>Discovery sessions</strong> with the client to learn about their business needs and get to know the
          team and work methodology.
        </>,
        <>
          <strong>Review and analysis</strong> of the client&apos;s project.
        </>,
        <>
          <strong>Deep dive</strong> into the client&apos;s problem.
        </>,
        <>
          <strong>Identify the scale of the problem</strong> and its impact on the project.
        </>,
        <>
          <strong>Set initial KPIs</strong> to define success.
        </>,
      ],
    },
    {
      name: 'Research',
      styleName: 'research-icon',
      icon: researchInMenu,
      iconOnLemon: researchOnLemon,
      activeIconOnLemon: researchOnLemonActive,
      description: [
        <>
          <strong>Analysis of the client&apos;s competition</strong> based on the client&apos;s input, inspiration and
          internal research.
        </>,
        <>
          <strong>Access to competitors&apos; product demos</strong> to analyze basic paths, process structure,
          information architecture, and availability approach (WCAG).{' '}
          <strong>
            Identifying areas for improvement in the client&apos;s products and conducting a comparative analysis
          </strong>{' '}
          of competitors&apos; products to generate new ideas.
        </>,
        <>
          <strong>Analysis of specific solutions from different industries</strong> to gain a broader perspective.
        </>,
        <>
          <strong>Target group research</strong> based on industry data, client data, and internal experience in the
          network industry to identify real audience needs and pain points.
        </>,
        <>
          <strong>Discovery workshops with the client</strong> to determine the product&apos;s value proposition and
          identify the product&apos;s pain points and blind spots.
        </>,
      ],
    },
    {
      name: 'Design',
      styleName: 'design-icon',
      icon: designInMenu,
      iconOnLemon: designOnLemon,
      activeIconOnLemon: designOnLemonActive,
      description: [
        <>
          <strong>Create an information architecture map</strong>.
        </>,
        <>
          <strong>Create mockups for processes</strong> and include them in the map.
        </>,
        <>
          <strong>Create mood boards, color pallets, and initial UI wireframes</strong> based on the research stage.
        </>,
        <>
          <strong>Create low-fidelity mockups (lo-fi)</strong> for testing the processes and flow of the entire
          application. Determine errors in processes and improve them iteratively.
        </>,
        <>
          <strong>Create high-fidelity mockups (hi-fi)</strong>.
        </>,
      ],
    },
    {
      name: 'Optimization',
      styleName: 'optimization-icon',
      icon: optimizationInMenu,
      iconOnLemon: optimizationOnLemon,
      activeIconOnLemon: optimizationOnLemonActive,
      description: [
        <>
          <strong>Conduct user tests</strong> on wireframes and implement improvements based on them.
        </>,
        <>
          <strong>Monitor the competition and market</strong> for continuous product development, align development with
          emerging users&apos; needs.
        </>,
      ],
    },
    {
      name: 'Testing',
      styleName: 'testing-icon',
      icon: testingInMenu,
      iconOnLemon: testingOnLemon,
      activeIconOnLemon: testingOnLemonActive,
      description: [
        <>
          <strong>Detect errors and implement improvements</strong> based on session recording and analytical data from
          dedicated tools (Hotjar, Google Analytics, etc.).
        </>,
        <>
          <strong>Conduct task tests</strong> - e.g. VPN / tunnel setup, protocol configuration.
        </>,
        <>
          <strong>Test the clarity</strong> of vocabulary and terminology.
        </>,
      ],
    },
  ],
};
