import React from 'react';

import * as styles from './testimonial-card.module.scss';

export const TestimonialCard = ({ text, person }) => {
  return (
    <div className={styles.testimonialContainer}>
      <span>{text}</span>
      <span>{person}</span>
    </div>
  );
};
