import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';

import { MenuSliderCard } from './menu-slider-card/MenuSliderCard';
import { DescriptionSlider } from './description-slider/DescriptionSlider';

import { sliderSettings } from './sliderSettings';

import './control-panel-on-mobile.scss';

export const ControlPanelOnMobile = ({ pillars }) => {
  const [activePillarIndex, setActivePillar] = useState('0');
  useEffect(() => {
    menuSliderdRef.current.slickGoTo(activePillarIndex);
    descriptionSliderRef.current.slickGoTo(activePillarIndex);
  });
  const menuSliderdRef = useRef(null);
  const descriptionSliderRef = useRef(null);

  const changeActivePillarOnNext = () => {
    const activeSlideIndex = document
      .querySelector('#pillarsMenuOnMobile .process-pillars-mobile-slider .slick-current')
      .getAttribute('data-index');
    setActivePillar(activeSlideIndex);
  };

  const changeActivePillarOnClick = (event) => {
    const clickedPillarIndex = event.target.closest('.slick-slide').getAttribute('data-index');
    setActivePillar(clickedPillarIndex);
  };

  return (
    <>
      <Slider {...sliderSettings} afterChange={changeActivePillarOnNext} ref={menuSliderdRef}>
        {pillars.map((pillar, index) => (
          <MenuSliderCard
            key={index}
            pillarIndex={index}
            pillarName={pillar.name}
            pillarIcon={pillar.icon}
            activePillarIndex={activePillarIndex}
            onClickHandler={changeActivePillarOnClick}
          />
        ))}
      </Slider>
      <DescriptionSlider pillars={pillars} sliderRef={descriptionSliderRef} setActivePillar={setActivePillar} />
    </>
  );
};
