import React from 'react';

import { FacebookIcon } from './FacebookIcon';
import { LinkdinIcon } from './LinkdinIcon';
import { TwitterIcon } from './TwitterIcon';
import { InstagramIcon } from './InstagramIcon';
import { DribbbleIcon } from './DribbbleIcon';
import { BehanceIcon } from './BehanceIcon';

export const Icon = ({ text }) => {
  switch (text) {
    case 'Facebook':
      return <FacebookIcon />;
    case 'LinkedIn':
      return <LinkdinIcon />;
    case 'Twitter':
      return <TwitterIcon />;
    case 'Instagram':
      return <InstagramIcon />;
    case 'Dribbble':
      return <DribbbleIcon />;
    case 'Behance':
      return <BehanceIcon />;
    default:
      return <div>{text}</div>;
  }
};
