import React from 'react';

import SEO from '@commons/SEO';
import { Navbar } from '@src/components/navbar/Navbar';

import {
  Header,
  WhyDoesUxMatter,
  WhyUxMattersSummary,
  FormWithChecklist,
  OurExpertiseInDesigning,
  ProcessPillars,
  RevampingNetworkApp,
  CaseStudies,
  Testimonials,
  BlogsSection,
  ContactForm,
  Footer,
  Sidebar,
  WavingHand,
} from '@pages-impl/ux-ui-design';

import featuredImage from '@images/header-images/featured-image-ux-ui-design.jpg';

import * as styles from './ux-ui-design.module.scss';

export default function UxUiDesign(props) {
  return (
    <div id="new-ux-ui-design-section" className={styles.mainContainer}>
      <SEO
        title="UX/UI Design for complex network applications | CodiLime"
        description="Design an engaging user experience that solves real business problems. Capitalize on Codilime’s 11 years of experience in designing network systems."
        featuredImage={featuredImage}
      />
      <Navbar {...props} />
      <WavingHand />
      <Sidebar />
      <Header />
      <WhyDoesUxMatter />
      <WhyUxMattersSummary />
      <FormWithChecklist />
      <OurExpertiseInDesigning />
      <ProcessPillars />
      <RevampingNetworkApp />
      <CaseStudies />
      <Testimonials />
      <BlogsSection />
      <ContactForm />
      <Footer />
    </div>
  );
}
