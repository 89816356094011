export const testimonials = [
  {
    person: 'Piotr Niedźwiedź, CEO   /   Neptune Labs, Inc.',
    text: `CodiLime’s expertise in software engineering was indispensable at the
      beginning of Neptune’s journey. CodiLime helped us build and release the
      first version of Neptune and convince investors that our product is a real
      game changer for data scientists.`,
  },
  {
    person: 'Rudra Rugge, VP of Engineering  /  Selector Software, Inc.',
    text: `With its expertise in both network engineering and software development,
    CodiLime helped us build and release a platform for operational intelligence
    of digital infrastructure. CodiLime UX designers and software engineers did a
    fantastic job ensuring our product offered first-rate user experience.`,
  },
  {
    person: 'Amit Jain, Co-Founder & CTO  /  Mesh7',
    text: `CodiLime has become our reliable technical partner helping us deliver 
    the first-in-class product for monitoring security in microservices. 
    CodiLime’s experienced project managers and engineers played an important role 
    in releasing the final product and thus keeping promises given to clients and investors.`,
  },
];
