import React from 'react';

import bulbIcon from '../shared/images/bulb.svg';
import networkIcon from '../shared/images/network.svg';
import knowledgeIcon from '../shared/images/knowledge.svg';
import terminalIcon from '../shared/images/terminal.svg';

export const ourExpertiseData = {
  sectionTitle: <h2>Our expertise in designing network applications</h2>,
  description: (
    <>
      At CodiLime, our design team has more than <strong>11 years of experience</strong> in crafting user experience and
      interface design for <strong>network applications.</strong> What sets us apart?
    </>
  ),
  callToAction: <strong>Would you like to know more about our expertise?</strong>,
  ourCompetenciesColumnOne: [
    {
      text: 'We have broad network industry know-how.',
      iconSrc: bulbIcon,
    },
    {
      text: 'We have experience in creating network applications for both vibrant startups and industry giants.',
      iconSrc: knowledgeIcon,
    },
  ],
  ourCompetenciesColumnTwo: [
    {
      text: 'We offer access to in-house network experts and engineers.',
      iconSrc: networkIcon,
    },
    {
      text: 'We develop and test product design solutions based on practical experience.',
      iconSrc: terminalIcon,
    },
  ],
};
