import React from 'react';
import Slider from 'react-slick';

import { SliderCard } from './slider-card/SlideCard';

import { sliderSettings } from './sliderSettings';
import * as styles from './mobile-slider.module.scss';

export const MobileSlider = ({ sliderData }) => {
  return (
    <div className={styles.sliderContainer}>
      <Slider {...sliderSettings}>
        {sliderData.map((sliderCardData, index) => (
          <SliderCard key={index} cardData={sliderCardData} />
        ))}
      </Slider>
    </div>
  );
};
