import React from 'react';

import firstImg from './images/our-expertise-in-designing-network-systems.svg';
import firstImgOnMobile from './images/check-out-our-ui-ux-case-studies.svg';

export const caseStudiesData = {
  sectionTitle: <h2>Check out our case studies</h2>,
  paragraph: 'Our expertise in designing complex network systems proven in practice',
  caseStudies: [
    {
      number: '01',
      description: 'Creating UX & UI of a portal for monitoring data visualization',
      href: 'https://codilime.com/case-studies/ux-ui-portal-for-monitoring-data-visualization/',
    },
    {
      number: '02',
      description: 'Building a UX prototype of a network monitoring platform',
      href: 'https://codilime.com/case-study/building-ux-prototype-of-network-monitoring-platform/',
    },
    {
      number: '03',
      description: 'Redesigning an outdated UI for network management application',
      href: 'https://codilime.com/case-study/redesigning-outdated-ui-for-network-management-application/',
    },
  ],
};

export const imagesArray = [firstImg];

export const imagesArrayOnMobile = [firstImgOnMobile];
