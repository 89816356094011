import React from 'react';

export const TwitterIcon = () => {
  return (
    <svg role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Twitter</title>
      <path
        d="M21.576 6.0482C20.856 6.3602 20.112 6.5762 19.32 6.6722C20.136 6.1922 20.76 5.4242 21.048 4.4882C20.28 4.9442 19.44 5.2562 18.552 5.4482C17.832 4.6802 16.8 4.2002 15.672 4.2002C13.488 4.2002 11.736 5.9762 11.736 8.1362C11.736 8.4482 11.76 8.7362 11.832 9.0242C8.56798 8.8802 5.68798 7.2962 3.74398 4.9202C2.35198 7.4162 3.91198 9.4802 4.94398 10.1762C4.31998 10.1762 3.69598 9.9842 3.16798 9.6962C3.16798 11.6402 4.53598 13.2482 6.31198 13.6082C5.92798 13.7282 5.06398 13.8002 4.53598 13.6802C5.03998 15.2402 6.50398 16.3922 8.20798 16.4162C6.86398 17.4722 4.89598 18.3122 2.37598 18.0482C4.12798 19.1762 6.19198 19.8242 8.42398 19.8242C15.672 19.8242 19.608 13.8242 19.608 8.6402C19.608 8.4722 19.608 8.3042 19.584 8.1362C20.4 7.5362 21.072 6.8402 21.576 6.0482Z"
        fill="#6A60A8"
      />
    </svg>
  );
};
