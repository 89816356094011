import React from 'react';

import * as styles from './why-does-ux-matter-summary.module.scss';

const summaryText = (
  <>
    That’s why in developing network applications, choosing{' '}
    <strong>the right UX design partner with a thorough understanding of network engineering</strong> is a matter of
    sound strategy.
  </>
);

export const WhyUxMattersSummary = () => {
  return (
    <section className={styles.container}>
      <div className={styles.backgroundShape}></div>
      {summaryText}
    </section>
  );
};
