export const sliderSettings = {
  arrows: true,
  className: 'vertical-slider',
  pauseOnFocus: true,
  infinite: true,
  vertical: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  initialSlide: -1,
  speed: 500,
};
