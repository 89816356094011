import React from 'react';

import { AnimatedIconPulsingCircle } from '@pages-impl/ux-ui-design/shared';

import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './waving-hand.module.scss';
import { Link } from '@commons/link/Link';

export const WavingHand = () => {
  return (
    <Link className={styles.wavingHand} href="#say-hello">
      <StaticImage src="./waving-hand.svg" alt="Say hello" placeholder="none" />
      <div className={styles.hiddenCircle}>
        <AnimatedIconPulsingCircle wrapperClassName={styles.pulsingCircle}>
          <strong>Say Hello!</strong>
        </AnimatedIconPulsingCircle>
      </div>
    </Link>
  );
};
