import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { appendTooltip } from '@pages-impl/ux-ui-design/shared/hubspot-form/appendTooltip';
import { privatePolicyInfo } from '@commons/append-tooltip/payload';
import { LoadingSpinner } from '@commons/loading-spinner/LoadingSpinner';

import { styleInputs } from '../../../helpers/styleInputs';

import '@pages-impl/ux-ui-design/shared/hubspot-form/common-forms-style.module.scss';
import './contact-hubspot-form.module.scss';

export const HubspotFormChecklist = ({ runSetFormSubmitted }) => {
  const policyInfo = privatePolicyInfo.type3;

  const onReady = () => {
    appendTooltip('#ux-ui-design-form-with-checklist', policyInfo);
    styleInputs('ux-ui-design-form-with-checklist');
  };

  return (
    <div className="old-hubspot-form">
      <HubspotForm
        region="na1"
        portalId="5206705"
        formId="f356fa37-380e-492f-8792-74ccdf2f470a"
        loading={<LoadingSpinner />}
        onReady={onReady}
        onSubmit={() => undefined}
        onFormSubmitted={() => {
          runSetFormSubmitted(2);
        }}
      />
    </div>
  );
};
