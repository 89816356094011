import React, { useState, useEffect } from 'react';

import cx from 'classnames';

import { AnimatedButton, SocialMediaLink } from '@pages-impl/ux-ui-design/shared';

import { socialMediaData, linksData } from '../payload';

import logoTop from '../images/codi-lime-background-top.svg';
import logo from '../images/codi-lime.svg';
import logoBottom from '../images/codi-lime-background-bottom.svg';

import { getVertical } from '../helpers';

import hand from '../images/hand.svg';

import * as styles from './menu.module.scss';
import { Link } from '@commons/link/Link';

export const Menu = ({ currentAnchor, toggleOpen }) => {
  const [vertical, setVertical] = useState('-8px');

  useEffect(() => setVertical(getVertical(currentAnchor, linksData)), [currentAnchor]);
  return (
    <div className={styles.backdrop} onClick={toggleOpen}>
      <div className={styles.menuWrapper}>
        <img loading="lazy" src={logoTop} alt="Codi Lime" className={styles.logoImage} />
        <img loading="lazy" src={logo} alt="Codi Lime" className={styles.logo} />
        <nav
          className={styles.menu}
          style={{ '--vertical': vertical }}
          onMouseLeave={() => setVertical(getVertical(currentAnchor, linksData))}
        >
          {linksData.map((link, index) => (
            <Link
              key={index}
              href={link.url}
              className={currentAnchor === link.url ? cx(styles.link, styles.activeLink) : styles.link}
              onMouseEnter={() => setVertical(getVertical(link.url, linksData))}
              onClick={() => setVertical(getVertical(link.url, linksData))}
            >
              {link.text}
            </Link>
          ))}
        </nav>
        <div className={styles.menuFooter}>
          <div className={styles.socialMediaContainer}>
            {' '}
            {socialMediaData.map((socialMedia, index) => (
              <SocialMediaLink key={index} url={socialMedia.url} alt={socialMedia.alt} />
            ))}
          </div>
          <AnimatedButton
            text="Say Hello!"
            iconSrc={hand}
            custonAnimatedButtonStyles={styles.animatedButtonContainer}
            url={'#say-hello'}
            self={true}
          />
        </div>
        <img loading="lazy" src={logoBottom} alt="Codi Lime" className={styles.logoImageBottom} />
      </div>
    </div>
  );
};
