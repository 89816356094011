import React from 'react';

import { AnimatedButton } from '@pages-impl/ux-ui-design/shared/animated-button/AnimatedButton';

import arrowIcon from '@pages-impl/ux-ui-design/shared/images/upwards-arrow_black.svg';
import * as styles from './accept-form.module.scss';

export const AcceptForm = ({ setFormSide, acceptFormData: { thankYouTitle, thankYouDescription, buttonText } }) => {
  return (
    <div id="accept-form-container">
      <h2 className={styles.thankYouTitle}>{thankYouTitle}</h2>
      <p className={styles.thankYouDescription}>{thankYouDescription}</p>
      <AnimatedButton
        text={buttonText}
        iconSrc={arrowIcon}
        custonAnimatedButtonStyles={styles.animatedButtonContainer}
        onClick={() => setFormSide(0)}
      />
    </div>
  );
};
