import React, { useState } from 'react';
import { NumberedParagraph } from '@pages-impl/ux-ui-design/shared/numbered-paragraph/NumberedParagraph';
import cx from 'classnames';

import './control-panel.scss';
import * as styles from './control-panel.module.scss';
import { Link } from '@commons/link/Link';

export const ControlPanel = ({ caseStudies }) => {
  const [activeCaseStudy, setActiveCaseStudy] = useState(-1);

  return (
    <div id="ux-design-case-study-container" className={styles.containerWithCaseStudies}>
      {caseStudies.map((caseStudy, index) => (
        <div className="caseStudyOuterContainer" key={`case_study_${index}`} id={`caseStudyContainer_${index}`}>
          <Link href={caseStudy.href}>
            <div
              className={styles.caseStudyContainer}
              onMouseEnter={(e) => setActiveCaseStudy(e.target.closest('.caseStudyOuterContainer').id.slice(-1))}
              onMouseLeave={() => setActiveCaseStudy(-1)}
            >
              <NumberedParagraph
                number={caseStudy.number}
                text={caseStudy.description}
                customStyles={`${
                  parseInt(activeCaseStudy) === index
                    ? 'number-with-paragraph active-case-study'
                    : 'number-with-paragraph'
                }`}
              />
              {parseInt(activeCaseStudy) === index ? (
                <div className={cx(styles.arrowContainer, styles.activeArrowContainer)}></div>
              ) : (
                <div className={cx(styles.arrowContainer, styles.inactiveArrowContainer)}></div>
              )}
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};
