import React from 'react';
import cx from 'classnames';

import { AnimatedIconWithCircles } from '@pages-impl/ux-ui-design/shared';

import backgroundCircle from '@pages-impl/ux-ui-design/revamping-network-app/images/background-circle.svg';
import semicircle from '@pages-impl/ux-ui-design/revamping-network-app/images/semicircle.svg';

import * as styles from './slider-card.module.scss';

export const SliderCard = ({ cardData, activeCard }) => {
  const { title, description, icon, activeIcon } = cardData;

  if (activeCard === title) {
    return (
      <div className={styles.cardOuterContainer}>
        <div className={cx(styles.cardContainer, styles.activeCardContainer)}>
          <AnimatedIconWithCircles iconSrc={activeIcon} rotationMultiplier={4} />
          <p className={cx(styles.cardTitle, styles.activeCardTitle)}>{title}</p>
          <p className={cx(styles.cardDescription, styles.activeCardDescription)}>{description}</p>
          <img loading="lazy" className={styles.semicircle} src={semicircle} alt="semi circle" />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.cardOuterContainer}>
        <div className={cx(styles.cardContainer, styles.inactiveCardContainer)}>
          <img loading="lazy" className={styles.inactiveIcon} src={icon} />
          <p className={cx(styles.cardTitle, styles.inactiveCardTitle)}>{title}</p>
          <p className={cx(styles.cardDescription, styles.inactiveCardDescription)}>{description}</p>
          <img loading="lazy" className={styles.fistCircle} src={backgroundCircle} alt="background circle" />
          <img loading="lazy" className={styles.secondCircle} src={backgroundCircle} alt="background circle" />
          <img loading="lazy" className={styles.thirdCircle} src={backgroundCircle} alt="background circle" />
        </div>
      </div>
    );
  }
};
