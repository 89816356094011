import React, { useState } from 'react';

import { DownloadChecklist } from './download-checklist/DownloadChecklist';
import { AcceptForm } from './accept-form/AcceptForm';
import { CompleteForm } from './complete-form/CompleteForm';

import { downloadChecklistData, acceptFormData, completeFormData } from './payload';

import * as styles from './form-with-checklist.module.scss';

export const FormWithChecklist = () => {
  const [formSide, setFormSide] = useState(0);
  return (
    <section className={styles.container} id="download-checklist">
      {formSide == 0 ? (
        <DownloadChecklist data={downloadChecklistData} setFormSide={setFormSide} />
      ) : formSide == 1 ? (
        <CompleteForm data={completeFormData} setFormSide={setFormSide} />
      ) : (
        <AcceptForm data={acceptFormData} setFormSide={setFormSide} />
      )}
    </section>
  );
};
