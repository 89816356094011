import React from 'react';

import { AnimatedParagraphTitle, NumberedParagraph } from '@pages-impl/ux-ui-design/shared';

import { parapgraphsData } from './payload';

import * as styles from './why-does-ux-matter.module.scss';

export const WhyDoesUxMatter = () => {
  const { sectionTitle, paragraphOne, paragraphTwo } = parapgraphsData;

  return (
    <section className={styles.container} id="ux-matter">
      <AnimatedParagraphTitle title={sectionTitle} />
      <div className={styles.paragraphsContainer}>
        <div>
          <NumberedParagraph number="01" text={paragraphOne} />
        </div>
        <div>
          <NumberedParagraph number="02" text={paragraphTwo} />
        </div>
      </div>
    </section>
  );
};
