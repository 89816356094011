import { appendJquery } from '@src/utils/appendJquery';

import infoIcon from '@pages-impl/ux-ui-design/shared/images/question-mark.svg';
import acceptIcon from '@pages-impl/ux-ui-design/shared/images/check.svg';
import closeIcon from '@pages-impl/ux-ui-design/shared/images/cross.svg';
import submitIcon from '@pages-impl/ux-ui-design/shared/images/submit-icon.svg';

export const appendTooltip = (formId, privatePolicyInfo) => {
  appendJquery();
  const formContainer = formId + ' .hs-form-booleancheckbox';
  const parentContainer = document.querySelector(formContainer);

  createanimateButton(formId);
  const tooltipContainer = document.createElement('details');
  tooltipContainer.setAttribute('class', 'tooltip');
  tooltipContainer.appendChild(createSumaryWithInfoIcon());
  tooltipContainer.appendChild(createTooltipPopUp(privatePolicyInfo));
  parentContainer.appendChild(tooltipContainer);
  resetTextarea(formId);
};

const resetTextarea = (formId) => {
  const inputElement = document.querySelector(formId + ' form .hs-fieldtype-textarea[name="message"]');
  if (inputElement) {
    inputElement.value = '';
    inputElement.dispatchEvent(new Event('input', { bubbles: true }));
  }
};

const createanimateButton = (formId) => {
  const buttonContainer = document.querySelector(formId + ' .actions');
  addChildrenToAnimatedButton(buttonContainer, submitIcon, 'Submit');
};

const createSumaryWithInfoIcon = () => {
  // create info icon
  const img = document.createElement('img');
  img.setAttribute('src', infoIcon);
  img.setAttribute('alt', 'info');
  img.setAttribute('loading', 'lazy');
  img.setAttribute('class', infoIcon);

  //create summary container with img as a child
  const summary = document.createElement('summary');
  summary.setAttribute('class', 'tooltip-icon');
  summary.appendChild(img);
  return summary;
};

const createTooltipPopUp = (privatePolicyInfo) => {
  // create tooltip pop up with content
  const tooltipPopUp = document.createElement('div');
  tooltipPopUp.setAttribute('class', 'tooltip-popup');

  const tooltipContent = document.createElement('div');
  tooltipContent.setAttribute('class', 'tooltip-content');
  tooltipContent.appendChild(createCloseIcon());
  tooltipContent.appendChild(createParagraph(privatePolicyInfo));
  tooltipContent.appendChild(createParagraphWIthLink());
  tooltipContent.appendChild(createAnimatedButton());
  tooltipPopUp.appendChild(tooltipContent);
  return tooltipPopUp;
};

const createCloseIcon = () => {
  const icon = document.createElement('img');
  icon.setAttribute('src', closeIcon);
  icon.setAttribute('class', 'close-icon');
  //close tooltip after click on icon
  icon.onclick = function (event) {
    event.preventDefault();
    event.target.closest('details').removeAttribute('open');
  };
  return icon;
};

const createParagraph = (paragraphText) => {
  const paragraph = document.createElement('p');
  paragraph.innerHTML = paragraphText;
  return paragraph;
};

const createParagraphWIthLink = () => {
  const paragraph = document.createElement('p');
  paragraph.innerHTML = 'For more information see our ';

  const hyperlink = document.createElement('a');
  hyperlink.setAttribute('href', 'https://codilime.com/privacy-policy/');
  hyperlink.innerHTML = 'Privacy Policy';
  hyperlink.setAttribute('rel', 'noopener');
  hyperlink.setAttribute('target', '_blank');
  paragraph.appendChild(hyperlink);
  paragraph.innerHTML += '.';
  return paragraph;
};

const createAnimatedButton = () => {
  const buttonContainer = document.createElement('div');
  buttonContainer.setAttribute('class', 'animated-button-container');

  const button = document.createElement('button');
  button.setAttribute('class', 'button-ok');
  //close tooltip after click on button
  button.onclick = function (event) {
    event.preventDefault();
    event.target.closest('details').removeAttribute('open');
  };

  addChildrenToAnimatedButton(button, acceptIcon, 'Ok, got it!');
  buttonContainer.appendChild(button);

  return buttonContainer;
};

const addChildrenToAnimatedButton = (buttonContainer, iconSrc, buttonTittle) => {
  const title = document.createElement('span');
  title.innerHTML = buttonTittle;
  buttonContainer.appendChild(title);

  const imgBox = document.createElement('svg');
  imgBox.setAttribute('vievBox', '0 0 350 100');

  const image = document.createElement('img');
  image.setAttribute('src', iconSrc);

  buttonContainer.appendChild(imgBox);
  buttonContainer.appendChild(image);
};
