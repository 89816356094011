import React, { useState } from 'react';

import { ControlPanel } from './control-panel/ControlPanel';

import lemonsIcon from '../images/lemons.svg';

import * as styles from './control-panel-on-desktop.module.scss';
import './lemon-icons.scss';

export const ControlPanelOnDesktop = ({ pillars }) => {
  const [activePillars, setActivePillars] = useState('Strategy');
  return (
    <>
      <div className={styles.imageWithPlanets} id="lemonWithIcons">
        <img loading="lazy" src={lemonsIcon} alt="five pillars of our process" className={styles.lemonImg} />
        {pillars.map((pillar, index) => (
          <img
            loading="lazy"
            src={`${activePillars === pillar.name ? pillar.activeIconOnLemon : pillar.iconOnLemon}`}
            alt={`${pillar.name} pillar`}
            key={`planet${index}`}
            id={pillar.name}
            className={pillar.styleName}
            onClick={(e) => setActivePillars(e.target.id)}
          />
        ))}
      </div>
      <ControlPanel pillars={pillars} activePillars={activePillars} setActivePillars={setActivePillars} />
    </>
  );
};
