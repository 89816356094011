import React from 'react';

import Slider from 'react-slick';

import { BlogCard } from './blog-card/BlogCard';
import { AnimatedButton } from '../shared/animated-button/AnimatedButton';

import { graphql, useStaticQuery } from 'gatsby';

import { sliderSettings } from './sliderSettings';
import { paths } from '@src/constants/pathsEnum';

import * as styles from './blog.module.scss';

export const BlogsSection = () => {
  const blogQuery = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { blogId: { in: ["pdPei80r2", "dSNapnMao", "o4lOwTzAn", "b0WUzSeCn", "u0tDaLq0n"] } } }
      ) {
        nodes {
          frontmatter {
            path
            date
            title
          }
        }
      }
    }
  `);

  const blogPostsData = blogQuery.allMarkdownRemark.nodes;

  return (
    <section className={styles.container} id="blog">
      <h2 className={styles.mainHeadline}>Our blog</h2>
      <p className={styles.mainParagraph}>
        Learn more about our approach to ensuring great user experience in network applications
      </p>
      <Slider {...sliderSettings}>
        {blogPostsData.map((post, index) => {
          const { path, date, title } = post.frontmatter;
          return <BlogCard key={index} text={title} date={date} url={path} />;
        })}
      </Slider>
      <div className={styles.animatedButton}>
        <AnimatedButton
          text="Discover all blog posts"
          url={paths.BLOG}
          custonAnimatedButtonStyles={styles.animatedButtonContainer}
        />
      </div>
    </section>
  );
};
