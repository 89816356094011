import React from 'react';

import { imagesArray, imagesArrayOnMobile } from '../payload';

import * as styles from './image-with-screens.module.scss';

export const ImageWithScreens = () => {
  const imagesLink = imagesArray;
  /*uncomment in case of more than one picture
  const [activeImg, setActiveImg] = useState(0);
  const numberImages = imagesLink.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImg((activeImg) => (activeImg + 1) % numberImages);
    }, 3000);
    return () => clearInterval(interval);
  }, []);*/

  return (
    <>
      <div className={styles.containerForImage}>
        <img loading="lazy" src={imagesLink[0]} alt="Our expertise in designing network systems" />
      </div>
      <div className={styles.containerForImageOnMobile}>
        <img loading="lazy" src={imagesArrayOnMobile[0]} alt="Check out our ui ux case studies" />
      </div>
    </>
  );
};
