// extracted by mini-css-extract-plugin
export var container = "header-module--container--ce893";
export var contentWrapper = "header-module--content-wrapper--1bf19";
export var headerTitle = "header-module--header-title--20227";
export var infiniteUpAndDown = "header-module--infinite-up-and-down--eeb2f";
export var laptopImageContainer = "header-module--laptop-image-container--69d49";
export var leftToRight = "header-module--left-to-right--03a2f";
export var mobileInfiniteUpAndDown = "header-module--mobile-infinite-up-and-down--42604";
export var mobileVerticalRunner = "header-module--mobile-vertical-runner--8ac0b";
export var networkString = "header-module--network-string--3d43e";
export var paragraphContainer = "header-module--paragraph-container--283c2";
export var paragraphText = "header-module--paragraph-text--babd2";
export var runnersContainer = "header-module--runners-container--d2ef6";
export var scrollDownIcon = "header-module--scroll-down-icon--6df3c";
export var verticalRunner = "header-module--vertical-runner--28378";