import React from 'react';

import { FormBorder } from './form-border/FormBorder';
import { FormContainer } from './form-container/FormContainer';

import { contactFormData } from './payload';

import * as styles from './contact-form.module.scss';

export const ContactForm = () => {
  const { borderData, formData } = contactFormData;

  return (
    <section className={styles.container} id="say-hello">
      <FormBorder {...borderData} />
      <FormContainer {...formData} />
    </section>
  );
};
