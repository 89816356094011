import React, { useState } from 'react';

import { CompleteForm } from './complete-form/CompleteForm';
import { AcceptForm } from './accept-form/AcceptForm';

import * as styles from './form-container.module.scss';

export const FormContainer = (formData) => {
  const [formSide, setFormSide] = useState(0);
  const { formTitle, ...acceptFormData } = formData;

  return (
    <div className={styles.formContainer}>
      {formSide === 0 ? (
        <CompleteForm setFormSide={setFormSide} formTitle={formTitle} />
      ) : (
        <AcceptForm setFormSide={setFormSide} acceptFormData={acceptFormData} />
      )}
    </div>
  );
};
