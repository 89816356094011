import React from 'react';
import cx from 'classnames';

import * as styles from './menu-slider-card.module.scss';

export const MenuSliderCard = ({ pillarName, pillarIcon, activePillarIndex, onClickHandler, pillarIndex }) => {
  if (pillarIndex === parseInt(activePillarIndex)) {
    return (
      <div className={cx(styles.iconContainerInMenu, styles.activeIconContainerInMenu)}>
        <img loading="lazy" src={pillarIcon} alt="pillar icon" className={styles.imgOnMenu}></img>
        <div className={styles.pillarName}>{pillarName}</div>
      </div>
    );
  } else {
    return (
      <div className={styles.iconContainerInMenu} onClick={(event) => onClickHandler(event)}>
        <img loading="lazy" src={pillarIcon} alt="pillar icon" className={styles.imgOnMenu}></img>
        <div className={styles.pillarName}>{pillarName}</div>
      </div>
    );
  }
};
