import React from 'react';

const commonData = {
  firstHeading: 'Checklist',
  secondHeading: <>8 things you can&apos;t skip when choosing a design partner</>,
};

export const downloadChecklistData = { ...commonData, buttonTitle: 'Download and find out' };

export const acceptFormData = {
  ...commonData,
  thankYouTitle: 'Thank you!',
  thankYouNote: 'Check your email box and discover what we have prepared for you!',
  thankYouButton: 'Close this window',
};

export const completeFormData = {
  ...commonData,
  formTitle: 'Download the check list and find out!',
};
