export const contactFormData = {
  borderData: {
    firstHeading: 'Data-driven design for network apps is within your reach.',
    secondHeading: 'Get in touch with us and get an offer customized to your case.',
  },
  formData: {
    formTitle: 'Say Hello!',
    thankYouTitle: 'Thank you for your message!',
    thankYouDescription: 'We will contact you soon.',
    buttonText: 'Write a message again',
  },
};
